import '../styles/index.css'
import '../styles/global.scss'
import { Provider } from 'next-auth/client'
import { init } from '../src/api/sentry'

init()

function MyApp({ Component, pageProps, err }) {


  return (
    <Provider session={pageProps.session}>
      <Component {...pageProps} err={err}/>
    </Provider>
    
  )
}

export default MyApp;
